<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <form-wizard
        :title="null"
        :subtitle="null"
        back-button-text="previous"
        next-button-text="next"
        @on-complete="onSubmit"
      >
        <tab-content title="Upload Documents" icon="feather icon-file-text">
          <div>
            <div v-if="quote.documents && quote.documents.length">
              <h3 class="mb-2">Quotation Document:</h3>
              <div class="d-flex justify-content-center flex-wrap">
                <div v-for="(document, index) in quote.documents" :key="index" class="d-flex my-1">
                  <b-button
                    class="d-flex align-items-center btn-sm mr-1 ml-2"
                    variant="outline-primary"
                    @click="openModal(document.path)"
                  >
                    <feather-icon icon="FileIcon" size="25" />
                    {{ document.name }}</b-button
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h3 class="mb-2">No Existing Quotation Document</h3>
            </div>
            <div class="d-flex justify-content-between align-items-center" />
            <h3 class="mt-2">Add Document:</h3>
            <div class="custom-file my-2">
              <input
                id="customFile"
                type="file"
                class="custom-file-input"
                accept=".pdf"
                multiple
                @change="handleFileUpload"
              />
              <label class="custom-file-label" for="customFile">Choose file</label>
            </div>
            <div v-if="addedDocuments.length">
              <h3 class="mb-2">Added Documents:</h3>
              <div class="d-flex justify-content-center flex-wrap">
                <div v-for="(document, index) in addedDocuments" :key="index" class="d-flex my-1">
                  <b-button
                    class="d-flex align-items-center btn-sm mr-1 ml-2"
                    variant="outline-primary"
                  >
                    <feather-icon icon="FileIcon" size="25" />
                    {{ document.name }}
                  </b-button>
                  <b-button class="btn-sm" variant="primary" @click="removeFile(index)">
                    <feather-icon icon="TrashIcon" size="15" class="text-white align-middle" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Order Checkout" icon="feather icon-shopping-cart">
          <h2 type="text" class="d-flex align-items-center">Additional Services</h2>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col md="1">
              <b-form-checkbox v-model="manpower" @change="toggleQuantityInput"
                >Manpower</b-form-checkbox
              >
            </b-col>
            <b-col md="1">
              <b-form-group :label="'Quantity'">
                <input
                  v-model="orderSummary.man_power"
                  :disabled="!manpower"
                  type="text"
                  class="form-control"
                  placeholder="Quantity"
                  aria-label="Quantity"
                  @input="computeTotalAdditionalServices"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" class="mt-1">
              <b-form-group :label="'Price per quantity'">
                <p type="text" class="form-control">
                  {{ $helpers.formatTotal(180) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="2" class="mt-1">
              <b-form-group :label="'Total Additional Services'">
                <p type="text" class="form-control">
                  {{ $helpers.formatTotal(orderSummary.total_man_power) }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="5">
              <b-form-group :label="'Remarks'">
                <input
                  v-model="orderSummary.remarks"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Remarks"
                  aria-label="Remarks"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <h2 type="text" class="d-flex align-items-center">Summary</h2>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <h5>Quotation Price:</h5>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(quote.quotation) }}
              </p>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <div class="d-flex align-items-center">
                <h5 class="mr-1">Insurance:</h5>
                <slot name="label">
                  <feather-icon
                    :id="`size-tooltip`"
                    style="margin-bottom: 5px"
                    size="20"
                    icon="InfoIcon"
                    :variant="'primary'"
                  />
                  <b-tooltip :target="`size-tooltip`" triggers="hover" no-fade>
                    {{
                      isInsured === 'Yes'
                        ? 'You have chosen to apply insurance on your order.'
                        : 'You have chosen not to apply insurance on your order'
                    }}
                  </b-tooltip>
                </slot>
              </div>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(quote.insurance) }}
              </p>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <h5>Tax:</h5>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(quote.tax) }}
              </p>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <h5>Total Quotation:</h5>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(quote.total) }}
              </p>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <h5>Additional Services:</h5>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(orderSummary.total_man_power) }}
              </p>
            </b-col>
          </b-row>

          <hr />
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="1">
              <h5>Grand Total:</h5>
            </b-col>
            <b-col md="2">
              <p class="form-control">
                {{ $helpers.formatTotal(orderSummary.amount) }}
              </p>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center align-items-center">
            <h5>
              Delivery Date ETA:
              <strong class="text-primary"
                ><u>{{ quote.delivery_date }}</u></strong
              >
            </h5>
          </div>
        </tab-content>
        <tab-content
          title="Billing Details"
          icon="feather icon-file-text"
          :before-change="validateFirstSteps"
        >
          <ValidationObserver ref="deliveryDetails" v-slot="{ passes }">
            <form @submit.prevent="passes(validateFirstSteps)">
              <SenderDetails ref="childComponentRef" @setSender="handleBillingAddress" />
            </form>
          </ValidationObserver>
        </tab-content>
        <tab-content title="Payment Method" icon="feather icon-dollar-sign">
          <UploadReceipt @handleReceipt="handleReceipt" @handleWrongReceipt="handleWrongReceipt" />
        </tab-content>
        <b-button slot="finish" class="wizard-btn" variant="primary"> Submit Receipt </b-button>
      </form-wizard>

      <b-modal
        ref="modal"
        size="lg"
        :title="'Quoted Document'"
        no-close-on-backdrop
        hide-footer
        centered
      >
        <div class="overflow-auto d-flex align-items-center justify-content-center">
          <template>
            <div>
              <iframe
                v-if="isPDF(src)"
                :src="src"
                frameborder="3"
                width="700"
                height="750"
                :allowfullscreen="true"
              />
              <div v-else class="text-center">No preview available.</div>
            </div>
          </template>
        </div>
      </b-modal>
      <template #overlay>
        <div class="text-center">
          <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="primary" />
          <h3 class="mt-3">Processing... Please wait.</h3>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UploadReceipt from '@/components/UploadReceipt.vue'
import { ValidationObserver } from 'vee-validate'
import { SenderDetails } from '@/components/CharteredTruck'

export default {
  components: {
    FormWizard,
    TabContent,
    UploadReceipt,
    SenderDetails,
    ValidationObserver,
  },
  data() {
    return {
      selectedFileName: ['Choose file'],
      isLoading: false,
      isSubmitting: false,
      isInsured: '',
      src: '',
      quote: {},
      mop: 'RECEIPT',
      manpower: false,
      pricePerQuantity: 180,
      addedDocuments: [],
      orderSummary: {
        order_id: this.$route.params.id,
        remarks: '',
        man_power: 0,
        total_man_power: 0,
      },
      selectedFileName: 'Choose file',
      filePreview: '',
      paymentReceipt: null,
      billing: null,
    }
  },

  computed: {
    loadingMessage() {
      if (this.isLoading) {
        return 'Fetching fields data...'
      }

      if (this.isCheckout) {
        return 'Processing Order...'
      }
      return ''
    },
  },
  created() {
    this.isLoading = true
    this.getQuote()
  },
  methods: {
    async getQuote() {
      const response = await this.$http.get(
        `quote/${this.$route.params.smarttruckid}?is_chartered=false`
      )
      if (response) {
        this.quote = response.data.data
        ;(this.orderSummary.quotation_price = this.quote.quotation),
          this.isInsured
            ? (this.orderSummary.insurance = this.quote.insurance)
            : (this.orderSummary.insurance = 0),
          (this.orderSummary.tax = this.quote.tax),
          (this.orderSummary.total_quotation = parseFloat(this.quote.total)),
          (this.orderSummary.serviceCharged = parseFloat(this.quote.quotation) * 0), // SC CHANGES // 0.08
          (this.orderSummary.amount =
            parseFloat(this.quote.total) +
            parseFloat(this.orderSummary.total_man_power) +
            parseFloat(this.orderSummary.serviceCharged))
        this.computeTotalAdditionalServices()
        this.$http.get(`get_fleet_order/${this.$route.params.id}`).then((response) => {
          if (response) {
            this.isInsured = response.data.data.insurance
          }
          this.isLoading = false
        })
      }
      this.isLoading = false
    },

    computeTotalAdditionalServices() {
      this.orderSummary.total_man_power = 0
      if (this.manpower) {
        const quantity = parseInt(this.orderSummary.man_power, 10)
        if (!isNaN(quantity) && quantity > 0) {
          this.orderSummary.total_man_power = quantity * this.pricePerQuantity
        }
      }
      this.orderSummary.amount =
        parseFloat(this.quote.total) +
        parseFloat(this.orderSummary.total_man_power) +
        parseFloat(this.orderSummary.serviceCharged)
    },

    toggleQuantityInput() {
      if (!this.manpower) {
        this.orderSummary.man_power = 0
        this.orderSummary.total_man_power = 0

        this.orderSummary.amount =
          parseFloat(this.quote.total) +
          parseFloat(this.orderSummary.total_man_power) +
          parseFloat(this.orderSummary.serviceCharged)
      }
    },

    handleFileUpload(event) {
      const selectedFiles = event.target.files
      this.addedDocuments = this.addedDocuments.concat(Array.from(selectedFiles))
      this.selectedFileNames = this.addedDocuments.map((file) => file.name)
    },

    openModal(type) {
      this.src = type
      this.$refs.modal.show()
    },

    isPDF(url) {
      return url.toLowerCase().endsWith('.pdf')
    },

    removeFile(index) {
      this.addedDocuments.splice(index, 1)
      this.selectedFileNames.splice(index, 1)
    },
    async onSubmit() {
      this.isLoading = true
      // Check if there is uploaded receipt
      if (!this.paymentReceipt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please upload receipt',
            icon: 'WarningIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }
      const formData = new FormData()
      formData.append('order_id', this.orderSummary.order_id)
      this.addedDocuments.forEach((document, index) => {
        formData.append(`documents[${index}]`, document)
      })

      // Update fleet order pricing
      const mop = await this.$http.post('order/update_payment_method', {
        order_id: this.orderSummary.order_id,
        payment_method: this.mop,
      })
      if (!mop) {
        throw new Error('Failed to update mode of payment')
      }

      // Update fleet order pricing
      const updateResponse = await this.$http.post('update_fleet_order_pricing', this.orderSummary)
      if (!updateResponse) {
        throw new Error('Failed to update fleet order pricing')
      }

      // Post selected quote to smart truck
      const smartTruckResponse = await this.$http.post('smart_truck_selected_quote', {
        quote_id: this.$route.params.smarttruckid,
        is_chartered: false,
      })
      if (!smartTruckResponse) {
        throw new Error('Failed to post selected quote to smart truck')
      }

      // Upload order documents
      const uploadResponse = await this.$http.post('upload_order_documents', formData)
      if (!uploadResponse) {
        throw new Error('Failed to upload order documents')
      }

      // UPLOAD CUSTOMER RECEIPT
      const formDataReceipt = new FormData()
      formDataReceipt.append('customer_receipt[]', this.paymentReceipt)
      formDataReceipt.append('order_id', this.orderSummary.order_id)
      Object.entries(this.billing).forEach(([key, value]) => {
        if (key === 'address') {
          Object.entries(this.billing.address).forEach(([key1, value1]) => {
            formDataReceipt.append(`billing[address][${key1}]`, value1)
          })
        } else {
          formDataReceipt.append(`billing[${key}]`, value)
        }
      })

      const uploadReceipt = await this.$http.post('upload_customer_receipt', formDataReceipt, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (uploadReceipt.data.status) {
        window.location.href = '/fleet-forwarding-delivery-success'
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: uploadReceipt.error.message,
            icon: 'WarningIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
      }
      this.isLoading = false
    },
    viewDocuments(row) {
      window.open(row, '_blank')
    },
    handleReceipt(value) {
      this.paymentReceipt = value
    },
    handleWrongReceipt() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Receipt must be image or pdf type only.',
          icon: 'WarningIcon',
          variant: 'warning',
        },
      })
    },
    handleBillingAddress(value) {
      this.billing = value
    },
    async validateFirstSteps() {
      try {
        const result = await this.$refs.childComponentRef.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
